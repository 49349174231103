import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-table-column-context" }
const _hoisted_2 = { class: "app-table-column-context__controller app-table-column-context-controller" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_app_table_header_cell_content = _resolveComponent("app-table-header-cell-content")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_form_button = _resolveComponent("form-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_icon_font, {
      class: "app-table-column-context__icon",
      icon: "swap",
      size: 9
    }),
    _createVNode(_component_draggable, {
      class: "app-table-column-context__container app-table-column-context-container",
      ref: "container",
      group: "headers",
      "item-key": "id",
      modelValue: _ctx.headers,
      onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.change($event))),
      onStart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('start', $event))),
      onEnd: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('end', $event)))
    }, {
      item: _withCtx(({ element: header }) => [
        _createVNode(_component_app_table_header_cell_content, {
          class: "app-table-column-context-container__item",
          "is-edit": true
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(header.name), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_form_button, {
        text: 'Сохранить',
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('save'))),
        class: "cp-margin__r--sx"
      }),
      _createVNode(_component_form_button, {
        text: 'Отменить',
        bordered: true,
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('cancel')))
      })
    ])
  ]))
}