
import './tableColumnContext.scss';

import { defineComponent, PropType } from 'vue';

import { EventSortHeader, TableHeaderInterface } from '@/interfaces/ui/table/header.interface';

import Draggable from 'vuedraggable';
import IconFont from '../../icons/IconFont.vue';
import FormButton from '@/components/form/Button/index.vue';
import AppTableHeaderCellContent from '@/components/ui/table/__components/TableHeaderCellContent.vue';

const component = defineComponent({
  name: 'AppTableColumnContext',
  emits: ['change', 'save', 'cancel'],
  components: {
    AppTableHeaderCellContent,
    IconFont,
    Draggable,
    FormButton,
  },
  props: {
    headers: { type: Array as PropType<TableHeaderInterface[]> },
  },
  methods: {
    change(e: EventSortHeader) {
      const { type, newIndex, oldIndex } = e;
      this.$emit('change', { type, newIndex, oldIndex });
    },
  },
});

export default component;
