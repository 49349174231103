import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "cp-flex align-items-center" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_4 = { class: "cp-text-w500" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_main = _resolveComponent("app-header-main")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_app_table_column_context = _resolveComponent("app-table-column-context")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_text_overflow = _resolveComponent("text-overflow")!
  const _component_star_icon = _resolveComponent("star-icon")!
  const _component_text_phone = _resolveComponent("text-phone")!
  const _component_status_active = _resolveComponent("status-active")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_app_row = _resolveComponent("app-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_app_header_main, {
            title: _ctx.settings.title,
            "filter-settings": _ctx.filterSettings,
            "is-settings-icon": true,
            "is-settings-state": _ctx.isSettingColumn,
            "is-to-add-hidden": !_ctx.canEditEmployee,
            "add-tooltip": "Добавить исполнителя",
            onToAdd: _ctx.toAdd,
            onResetFilters: _ctx.resetFilters,
            onUpdateList: _ctx.updateList,
            onToSettings: _ctx.toSettings,
            onReturnFromSettings: _ctx.closeSettings
          }, null, 8, ["title", "filter-settings", "is-settings-state", "is-to-add-hidden", "onToAdd", "onResetFilters", "onUpdateList", "onToSettings", "onReturnFromSettings"])
        ]),
        _: 1
      }),
      (Object.keys(_ctx.headers).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.values(_ctx.headers).length > 0)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small grow-1",
            "have-status": true,
            "get-status": _ctx.getStatus,
            headers: _ctx.headers,
            items: _ctx.settings.table.rows,
            "is-edit": _ctx.isSettingColumn,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            "start-drag": _ctx.startDrag,
            "end-drag": _ctx.endDrag,
            onChangePage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSort: _ctx.sort,
            onSortHeaders: _ctx.changeVisibleHeaders
          }, {
            top: _withCtx(() => [
              (_ctx.isSettingColumn)
                ? (_openBlock(), _createBlock(_component_app_table_column_context, {
                    key: 0,
                    class: "width-full cp-padding__bsx",
                    ref: "target",
                    headers: _ctx.hiddenHeaders,
                    onSave: _ctx.saveColumnSettings,
                    onCancel: _ctx.cancelColumnSettings
                  }, null, 8, ["headers", "onSave", "onCancel"]))
                : _createCommentVNode("", true)
            ]),
            "item-fullName": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_1, [
                (_ctx.anyActiveTab.length)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      class: "app-table-link",
                      to: { name: 'employees_schedule', params: { employeeId: item.id } }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(value), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(value), 1)),
                (item.isTest)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-competences": _withCtx(({ value }) => [
              (_openBlock(), _createBlock(_component_text_overflow, {
                key: value,
                value: value
              }, null, 8, ["value"]))
            ]),
            "header-item-rating": _withCtx(({ header }) => [
              _withDirectives(_createVNode(_component_star_icon, null, null, 512), [
                [_directive_tooltip, header.name]
              ])
            ]),
            "item-rating": _withCtx(({ value }) => [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formatRating(value)), 1)
            ]),
            "item-phone": _withCtx(({ value, item }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_phone, {
                    key: 0,
                    value: value,
                    onPhoneClick: ($event: any) => (_ctx.makeCall(item.id))
                  }, null, 8, ["value", "onPhoneClick"]))
                : _createCommentVNode("", true)
            ]),
            "item-role": _withCtx(({ value }) => [
              _createVNode(_component_status_active, {
                "is-active": _ctx.getStatusByRole(value)
              }, null, 8, ["is-active"])
            ]),
            "item-lmk": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_datetime, {
                    key: 0,
                    value: value,
                    "format-from": "YYYY-MM-DD",
                    "format-to": "DD.MM.YYYY"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            "item-selfEmployedDay": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_datetime, {
                    key: 0,
                    value: value,
                    "format-from": "YYYY-MM-DD",
                    "format-to": "DD.MM.YYYY"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            "item-belongingToCitizenship": _withCtx(({ value }) => [
              _createTextVNode(_toDisplayString(_ctx.getCitizenshipName(value)), 1)
            ]),
            "item-isSelfEmployed": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_icon_font, {
                    key: 0,
                    class: "cp-icon-primary-2",
                    icon: "check-mark",
                    size: "10"
                  }))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, "-"))
            ]),
            "item-lastCameOut": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_datetime, {
                    key: 0,
                    value: value,
                    "format-from": "YYYY-MM-DD",
                    "format-to": "DD.MM.YYYY"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["get-status", "headers", "items", "is-edit", "page", "total-pages", "total-items", "page-items", "start-drag", "end-drag", "onSelectAmount", "onSort", "onSortHeaders"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}