
import { Component, Vue, Watch } from '@/lib/decorator';
import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import FilterPreview from '@/components/FilterPreview.vue';

import EmployeesModule from '@/store/employees';
import AppTable from '@/components/ui/table/Table.vue';
import { StatusListInterface } from '@/interfaces/ui/table/status.interface';
import TextPhone from '@/components/table-items/TextPhone.vue';
import TextOverflow from '@/components/table-items/TextOverflow.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import moment from 'moment';
import StatusActive from '@/components/table-items/StatusActive.vue';
import AppTableColumnContext from '@/components/ui/table/TableColumnContext.vue';
import AppScrollbar from '@/components/ui/scroll/Scrollbar.vue';
import AppTableStatus from '@/components/ui/table/__components/TableStatus.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import IconFont from '@/components/icons/IconFont.vue';
import StarIcon from '@/components/icons/Star.vue';
import { EventChangeSortHeader, TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import { tabsNav as employeesTabs } from '@/store/employees/entityTabs';
import userModule from '@/store/user';
import { getEmployeePhone } from '@/api/employees';
import { makePhoneCall } from '@/lib/util/phone';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppRow,
    AppCol,
    AppTableStatus,
    AppScrollbar,
    AppTableColumnContext,
    StatusActive,
    TextDatetime,
    TextPhone,
    AppTable,
    AppHeaderMain,
    FilterPreview,
    TextOverflow,
    IconFont,
    StarIcon,
  },
})
export default class EmployeesMain extends Vue {
  headers: TableHeaderInterface[] = [];
  hiddenHeaders: TableHeaderInterface[] = [];
  statusList: StatusListInterface = {
    error: '#FF4B3A',
    warning: '#ffc42b',
  };

  getStatus(row: Record<string, string | number | boolean | null>): { color: string; tooltip: string } | undefined {
    const lmkDate = row.lmk;
    const todayTimestamp = new Date(moment().format('MM/DD/YYYY HH:MM')).getTime() / 1000;
    const lmkTimestamp =
      lmkDate !== null ? new Date(moment(lmkDate as string).format('MM/DD/YYYY HH:MM')).getTime() / 1000 : lmkDate;
    const daysLeft = 30;
    const day = 86400;

    const isExpired = lmkTimestamp && lmkTimestamp - todayTimestamp < 0;

    if (!row.lmk || isExpired) {
      return { color: this.statusList.error, tooltip: 'ЛМК не существует или истек срок действия' };
    }

    const isExpiredSoon =
      lmkTimestamp && lmkTimestamp - todayTimestamp > 0 && lmkTimestamp - todayTimestamp < day * daysLeft;

    if (isExpiredSoon) {
      return {
        color: this.statusList.warning,
        tooltip: 'Срок действия ЛМК истекает <30 дней',
      };
    }
  }

  get canEditEmployee(): boolean {
    return userModule.userHasPermission('CAN_EDIT_EMPLOYEE');
  }

  get isSettingColumn(): boolean {
    return EmployeesModule.isSettingColumn;
  }

  get settings(): PageInterface {
    return EmployeesModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return EmployeesModule.filterModule.filterSettings;
  }

  get anyActiveTab(): string {
    const employeesPath = Object.values(employeesTabs)
      .filter((tab: { pathName: string }) => {
        return userModule.canUserRead(tab.pathName);
      })
      .shift();

    return employeesPath ? employeesPath.pathName : '';
  }

  getCitizenshipName(id: string): string {
    let name = '-';
    EmployeesModule.citizenshipsList.forEach((item: { id: string; name: string }) => {
      if (item.id === id) {
        name = item.name;
      }
    });

    return name;
  }

  @Watch('settings.table.hiddenTitles')
  getHiddenTitles(hiddenTitles: TableHeaderInterface[]): void {
    this.hiddenHeaders = [...hiddenTitles];
  }

  @Watch('settings.table.titles')
  getTitles(titles: TableHeaderInterface[]): void {
    this.headers = [...titles];
  }

  toAdd(): void {
    this.$router.push({ name: 'employee_add' });
  }

  selectAmount(value: string): void {
    EmployeesModule.updatePageAmountItems(value);
  }

  formatRating(value: string): string {
    return value.substring(0, 3);
  }

  async resetFilters(): Promise<void> {
    await EmployeesModule.setPageToBegin();
    await EmployeesModule.clearSort();
    await EmployeesModule.filterModule.resetFilter();
    await EmployeesModule.getList();
  }

  async updateList(): Promise<void> {
    await EmployeesModule.setPageToBegin();
    await EmployeesModule.filterModule.updateFilter();
    await EmployeesModule.getList();
  }

  toSettings(): void {
    EmployeesModule.updateIsSettingsColumn(true);
  }

  closeSettings(): void {
    EmployeesModule.updateIsSettingsColumn(false);
  }

  sort(header: { id: string; sort: { value: string } }): void {
    EmployeesModule.sort({ field: header.id, sort: header.sort.value });
  }

  changeVisibleHeaders(e: EventChangeSortHeader): void {
    const tmphiddenTitles = [...this.hiddenHeaders];
    const tmpTitles = e.headers;
    let element;
    switch (e.changed.type) {
      case 'update':
        element = tmpTitles[e.changed.oldIndex];
        tmpTitles.splice(e.changed.oldIndex, 1);
        tmpTitles.splice(e.changed.newIndex, 0, element);
        this.headers = tmpTitles;

        return;
      case 'add':
        element = tmphiddenTitles[e.changed.oldIndex];
        tmphiddenTitles.splice(e.changed.oldIndex, 1);
        tmpTitles.splice(e.changed.newIndex, 0, element);
        this.hiddenHeaders = tmphiddenTitles;
        this.headers = tmpTitles;

        return;
      case 'remove':
        element = tmpTitles[e.changed.oldIndex];
        tmpTitles.splice(e.changed.oldIndex, 1);
        tmphiddenTitles.splice(e.changed.newIndex, 0, element as never);
        this.hiddenHeaders = tmphiddenTitles;
        this.headers = tmpTitles;

        return;
    }
  }

  startDrag(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.addEventListener('dragover', this.dragenter, { capture: true });
    element.addEventListener('dragleave', this.dragleave, { capture: false });
  }

  endDrag(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.removeEventListener('dragover', this.dragenter);
    element.removeEventListener('dragleave', this.dragleave);
  }

  dragenter(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.classList.add('target-hover');
  }

  dragleave(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.classList.remove('target-hover');
  }

  saveColumnSettings(): void {
    EmployeesModule.saveSettingsToStore({
      hidden: this.hiddenHeaders,
      visible: this.headers,
    });
  }

  cancelColumnSettings(): void {
    this.closeSettings();

    this.hiddenHeaders =
      EmployeesModule.hiddenTitles && EmployeesModule.hiddenTitles.length
        ? [...EmployeesModule.hiddenTitles]
        : 'hiddenTitles' in this.settings.table && Array.isArray(this.settings.table.hiddenTitles)
        ? [...this.settings.table.hiddenTitles]
        : [];

    this.headers =
      EmployeesModule.orderTitles && EmployeesModule.orderTitles.length
        ? [...EmployeesModule.orderTitles]
        : 'titles' in this.settings.table && Array.isArray(this.settings.table.titles)
        ? [...this.settings.table.titles]
        : [];
  }

  getStatusByRole(role: string): boolean | null {
    switch (role) {
      case 'ROLE_EMPLOYEE':
        return true;
      case 'ROLE_DEACTIVATED':
        return false;
      default:
        return null;
    }
  }

  async makeCall(employeeId: string): Promise<void> {
    try {
      const phone = await getEmployeePhone(employeeId);
      makePhoneCall(phone);
    } catch (error) {
      console.error(error);
    }
  }

  mounted(): void {
    EmployeesModule.init();
  }
}
