import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    style: _normalizeStyle(`max-width: ${_ctx.maxWidth}`),
    class: "text-overflow"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.value), 1)
    ]),
    _: 1
  }, 8, ["style"])), [
    [_directive_tooltip, _ctx.value]
  ])
}