
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TextOverflow',
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    value: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '120px',
    },
  },
});
